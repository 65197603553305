import { useTranslation } from 'react-i18next';

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const languageOptions = [
    {
      label: '한국어',
      value: 'ko',
    },
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'Tiếng Việt',
      value: 'vi',
    },
    {
      label: 'Polskie',
      value: 'pl',
    },
    {
      label: 'Deutsch',
      value: 'de',
    },
    {
      label: 'bahasa Indonesia',
      value: 'id',
    },
    {
      label: '日本語',
      value: 'ja',
    },
    {
      label: 'ไทย',
      value: 'th',
    },
    {
      label: 'español',
      value: 'es',
    },
    {
      label: '中文(简体)',
      value: 'zh_cn',
    },
    {
      label: '漢語(繁體)',
      value: 'zh_tw',
    },
    {
      label: 'ភាសាខ្មែរ',
      value: 'km',
    },
    {
      label: 'नेपाली',
      value: 'ne',
    },
    {
      label: 'မြန်မာဘာသာ',
      value: 'my',
    },
    {
      label: 'Монгол хэл',
      value: 'mn',
    },
  ];

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  const languageKey = i18n.language;

  return {
    languageOptions,
    languageKey,
    changeLanguage,
  };
};
