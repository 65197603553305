import i18n from 'i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    backend: {
      loadPath: 'https://caas-locales.s3.ap-northeast-2.amazonaws.com/{{lng}}/{{ns}}.json',
    },
    ns: ['global', 'error_code', 'error_page', 'worker_web'],
    lng: 'ko',
    fallbackLng: ['en', 'ko'],
    interpolation: { escapeValue: false },
    react: {
      useSuspense: true,
    },
    load: 'currentOnly',
    supportedLngs: [
      'ko',
      'en',
      'vi',
      'pl',
      'de',
      'id',
      'ja',
      'th',
      'es',
      'zh_cn',
      'zh_tw',
      'km',
      'ne',
      'my',
      'mn',
    ],
  });

export default i18n;
