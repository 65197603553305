import { ReactNode, createContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { FileModel, createAxios, useProjectParams } from 'utils';

export interface ProfileModel {
  agreements: {
    employeePledge?: {
      assignedAt: string;
      safetyEquipment: {
        safetyHelmet: boolean;
        safetyShoes: boolean;
        safetyBelt: boolean;
      };
    };
    personalInfoConsent?: {
      assignedAt: string;
      attendanceDataAgreement: boolean;
      personalDataAgreement: boolean;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    safetyLiability?: any;
  };
  birth: string;
  createdAt: string;
  name: string;
  phoneNumber: string;
  process: {
    _id: string;
    title: string;
  };
  purchaseCompany: { name: string; _id: string };
  region: string;
  residenceDuration: {
    startDate: string | null;
    endDate: string | null;
  };
  residenceQualification?: string;
  safetyTrainingCertFile: FileModel | null;
  safetyTrainingCertNumber: string | null;
  signature: FileModel | null;
  _id: string;
}

interface ProfileProviderProps {
  children: ReactNode;
}

interface ProfileContextValue {
  profile?: ProfileModel;
  isLoading?: boolean;
  isLoggedIn?: boolean;
  refetch?: () => void;
}

export const ProfileContext = createContext<ProfileContextValue>({});

export const ProfileProvider = (props: ProfileProviderProps) => {
  const { children } = props;
  const { projectId } = useProjectParams();
  const token = window.localStorage.getItem('JWT_TOKEN');
  const prevProjectId = window.localStorage.getItem('PROJECT_ID');

  const isLoggedIn = useMemo(() => {
    if (!token) return false;
    if (projectId !== prevProjectId) return false;
    return true;
  }, [token, prevProjectId, projectId]);

  const fetchData = async () => {
    const { data } = await createAxios().get(`/worker/projects/${projectId}`);
    return data;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['fetch-worker-data', projectId],
    queryFn: fetchData,
    enabled: isLoggedIn,
  });

  return (
    <ProfileContext.Provider value={{ profile: data, isLoading, isLoggedIn, refetch }}>
      {children}
    </ProfileContext.Provider>
  );
};
